import mockType from '../../utils/constants/mocks/common/components/types.mock.json';
import mockTypeFilter from '../../utils/constants/mocks/common/components/type-filter.mock.json';
import mockOptionsState from '../../utils/constants/mocks/common/components/options-state.mock.json';
import { ConnectionService } from 'src/app/service/connection/connection.service';
import mockStatus from '../constants/mocks/common/components/status.mock.json';
import { TagSpicInterface } from '../models/tags.interface';
import { SwUpdate } from '@angular/service-worker';
import { DataModal } from '../models/modal.interface';
import { EventEmitter } from '@angular/core';
import { ModalLoadingComponent, PlotlyService } from '@induxtry/library';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RequestAttribute } from '../models/http.interface';
import { DataCardDocument } from '../models/card-document.interface';
import moment from 'moment';
import { Router } from '@angular/router';
import { LinkInterface } from '@induxtry/library/utils/models/common.interface';
import html2canvas from 'html2canvas';
import { LegendGraphic } from '../models/graphics.interface';
import { environment } from 'src/environments/environment';
import { ConfigApiDynamicTable } from '@induxtry/library/utils/models/table.interface';
import { AuthService } from 'src/app/service/auth/auth.service';
import mergeImages from 'merge-base64';
import domtoimage from 'dom-to-image-more';
import { DomSanitizer } from '@angular/platform-browser';

/** * FU - 001 - Converte o tipo do documento *
 *
 * @param {string} type - Sigla do tipo do documento.
 * @returns {string} Retorna a descrição do tipo *
 * @throws {  } null.
 *
 * @example
 * convertTypeDocument('si') => irá retornar 'SI - SS'.
 **/
export function convertTypeDocument(type: string): string {
  return (mockType[type] ? mockType[type] : type);
}

/** * FU - 002 - Ordena o array de objetos e de arrays em ordem crescente *
 *
 * @param { any } a - Primeiro param.
 * @param { any } b - Segundo param.
 * @param { string } property - Propriedade que vai fazer a comparação.
 * @returns { number } Retorna um numero para verificação se a param a é mais que o param b.
 * @throws {  } null.
 *
 * @example
 * const array = [{ name: 'Bruno' }, { name: 'Ana' }];
 * array.sort((a, b) => sortAsc(a, b, 'name'));
 * irá retornar [{ "name": "Ana" }, {  "name": "Bruno" }]
 **/
export function sortAsc(a: any, b: any, property: string): number{
  if (a[property] < b[property]) {
    return -1;
  } else if (a[property] > b[property]) {
    return 1;
  }
  return 0;
}

export function sortDesc(a: any, b: any, property: string): number{
  if (a[property] > b[property]) {
    return -1;
  } else if (a[property] < b[property]) {
    return 1;
  }
  return 0;
}

/** * FU - 003 - Verifica se o sistema está online ou não *
 *
 * @returns { boolean } Retorna true pra quando o sistema está online e false para quando está off-line.
 * @throws {  } null.
 **/
const connection = new ConnectionService();
export function isOnline() {
  return connection.status;
}

/** * FU - 003.1 - Retorna o Emitter que Verifica se o sistema está online ou não *
 *
 * @returns { EventEmitter<boolean> } Retorna event emitter true pra quando o sistema está online e false para quando está off-line.
 * @throws {  } null.
 **/
export function isOnlineEmitter(): EventEmitter<boolean> {
  return connection.isOnline;
}


/** * FU - 004 - Retorna a tag pelo tipo e pelo status *
 *
 * @param { string } type - Tipo do documento.
 * @param { string } status - status da tag.
 * @returns { TagSpicInterface } Retorna um objeto com icon, text e class da tag.
 * @throws {  } null.
 *
 * @example
 * const tag = returnTag('os', 'canceled');
 * irá retornar { "icon": "circle_cancel", "text": "Cancelada", "class": "error" }
 * obs: se não encontrar a tag no mock ele retorna uma padrão { icon: 'cash_euro', text: status, class: 'info' }
 **/
export function returnTag(type: string, status: string): TagSpicInterface {
  type = type?.replace('aes', 'pt')?.replace('cai', 'si');
  const slugStatus = slugify(status).replace(new RegExp(` `, 'g'), '-');
  if (type === 'pt' || type === 'si') {
    type = 'pt_si';
  }
  const objStatus = { ...mockStatus };
  return (objStatus[type] != null && objStatus[type][slugStatus] !== null && objStatus[type][slugStatus] !== undefined ? objStatus[type][slugStatus] : { key: 'default', icon: 'cash_euro', text: status, class: 'info' });
}

/** * FU - 005 - Gera todas as tags de um documento *
 *
 * @param { string } type - Tipo do documento.
 * @param { string } status - status da tag.
 * @param { string | null } hangTag - etiqueta da tag.
 * @returns { TagSpicInterface[] } Retorna um array de objeto com icon, text e class da tag.
 * @throws {  } null.
 *
 * @example
 * const tags = generateTags('ss', 'pending', 'remove');
 * irá retornar [
 *    { "icon": "cash_euro", "text": "Pendente", "class": "warning" },
 *    { "icon": "add_product", "text": "Etiqueta inserida", "class": "info" }
 * ]
 **/
export function generateTags(type: string, status: string, hangTag?: string): TagSpicInterface[] {
  const arrayTags = [];
  arrayTags.push(returnTag(type, status));
  if (type === 'ss') {
    hangTag = hangTag ? hangTag : 'inserted';
    const slugStatus = slugify(status).replace(new RegExp(` `, 'g'), '-');
    if ((['ss-atendida', 'ss-cancelada'].indexOf(slugStatus) !== -1) && hangTag === 'inserted') {
      arrayTags.push(returnTag('etiqueta', 'remove'));
    } else if ((['ss-pendente', 'ss-pepassada'].indexOf(status) !== -1) && hangTag !== 'inserted') {
      arrayTags.push(returnTag('etiqueta', 'inserted'));
    } else {
      arrayTags.push(returnTag('etiqueta', hangTag));
    }
  }
  return arrayTags;
}

/** * FU - 006 - Verifica se fois feito alguma alteração no código do projeto *
 *
 * @param { SwUpdate } swUpdate - obj de atualização.
 * @returns { Promise<any> } Se tiver realizado uma alteração no código ele atualiza a pwa automaticamente.
 * @throws {  } null.
 **/
export const checkForUpdates = (swUpdate: SwUpdate): (() => Promise<any>) => {
  return (): Promise<void> =>
    new Promise((resolve) => {
      if (swUpdate.isEnabled) {
        swUpdate.checkForUpdate();

        swUpdate.available.subscribe(() => {
          window.location.reload();
        });
      }
      resolve();
  });
};

/** * FU - 007 - Retorna o valor a ser retornado no modal de retirar cartão *
 *
 * @returns { DataModal } Retorna o valor a ser retornado no modal de retirar cartão
 * @throws {  } null.
 **/
export function dataModalHangTag(): DataModal {
  return {
    header: {
      title: 'Retirar cartão',
      icon: 'circle_alert',
      typeTitle: 'h5'
    },
    content: {
      typeContent: 'text',
      article: [
        {
          paragraph: [
            'Olá usuário! Ao confirmar a opção de “Retirar cartão” a situação do cartão passará a ser informada como “Cartão retirado” e essa ação não poderá ser revertida. Deseja continuar?'
          ]
        }
      ]
    },
    footer: {
      buttons: [
        { id: 'cancel', buttonInterface: { label: 'CANCELAR', icon: '', customStyle: 'tertiary' }, close: true },
        { id: 'confirm', buttonInterface: { label: 'CONFIRMAR', icon: '' }, close: true }
      ]
    }
  }
}

/** * FU - 008 - Transformar uma string em slug *
 *
 * @param { string } value - valor da string.
 * @returns { String } Retorna uma string convertida para slug
 * (obs: os espaçamentos não são substituidos a função foi criada para resetar as acentuações e caractes maiúsculos).
 * @throws {  } null.
 *
 * @example
 * const slugify = slugify('Téste Slug');
 * irá retornar 'teste slug'
 **/
export function slugify(value): string {
  return value.toString()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9 ]/g, '');
};

/** * FU - 009 - Fazer uma busca em todas as propriedades de um obj e/ou somente uma *
 *
 * @param { any } res - Objeto a ser feito a busca.
 * @param { string } value - valor a ser bus.
 * @param { RequestAttribute[] } attributes - attributes do select.
 * @param { verifySearch } verification - verificar qual busca vai ser feita.
 * @returns { boolean } retorna um boolean
 * (obs: true caso tenha encontrado e false para caso não tenha encontrado).
 * @throws {  } null.
 *
 * @example
 * const search = searchInObject({ name: 'teste', idade: 15 }, 'te', [], 'all');
 * irá retornar true
 **/
type verifySearch = 'all' | 'one';
export function searchInObject(res: any, value: string, attributes: RequestAttribute[], verification: verifySearch) {
  if (
    verifyAllObject(res, value, verification) &&
    verifyKeysObject(res, attributes)
  ) {
    return true;
  }
  return false;
}

/** * FU - 009.1 - Fazer uma busca em todas as propriedades de um obj exceto ('created_at' e 'updated_at') *
 *
 * @param { any } res - Objeto a ser feito a busca.
 * @param { string } value - valor a ser bus.
 * @param { verifySearch } verification - verificar qual busca vai ser feita.
 * @returns { boolean } retorna um boolean
 * (obs: true caso tenha encontrado e false para caso não tenha encontrado).
 * @throws {  } null.
 *
 * @example
 * const search = verifyAllObject({ name: 'teste', idade: 15 }, 'te', 'all');
 * irá retornar true
 **/
export function verifyAllObject(res: any, value: string, verification: verifySearch): boolean {
  if (verification === 'all' && value !== '') {
    const objKeys = Object.keys(res);
    for (const key of objKeys) {
      const valueRes = res[key] ? slugify(res[key]) : '';
      if (
        key !== 'created_at' &&
        key !== 'updated_at' &&
        valueRes.includes(slugify(value))
      ) {
        return true;
      }
    }
    return false;
  } else {
    return true;
  }
}

/** * FU - 009.2 - Fazer uma busca em somente algumas propriedades de um obj *
 *
 * @param { any } res - Objeto a ser feito a busca.
 * @param { RequestAttribute[] } attributes - attributes do select.
 * @returns { boolean } retorna um boolean
 * (obs: true caso tenha encontrado e false para caso não tenha encontrado).
 * @throws {  } null.
 *
 * @example
 * const search = verifyKeysObject({ name: 'teste', idade: 15 }, [{param: 'name', value: 'teste'}]);
 * irá retornar true
 **/

const datesVerifyFilter = [
  { property: 'startDate', initial: true },
  { property: 'occurrenceDateTime', initial: true },
  { property: 'creationDate', initial: true },
  { property: 'endDate', initial: false },
  { property: 'completionDate', initial: false },
  { property: 'deadlineDate', initial: false }
]

function verifyDateFilter(res: any, attr: RequestAttribute): boolean {
  const propertyDate = moment(res[attr.param]).format('YYYY-MM-DD'), attrDate = moment(attr.value).format('YYYY-MM-DD');
  const propDate = datesVerifyFilter.find(res => res.property === attr.param);
  if (propDate) {
    return propDate.initial ? propertyDate >= attrDate : propertyDate <= attrDate;
  }
  return false;
}

export function verifyKeysObject(res: any, attributes: RequestAttribute[]): boolean {
  if (attributes.length > 0) {
    const indexParam = attributes.findIndex(res => res.param === 'type');
    if (indexParam !== -1) {
      if (res.type === attributes[indexParam].value) {
        let beforeCount = 1, equalCount = 1;
        for (const attr of attributes) {
          let valueRes = res[attr.param] ? slugify(res[attr.param]) : '';
          const date = moment(res[attr.param]).isValid();
          if (['origin', 'page', 'limit', 'type'].indexOf(attr.param) === -1) {
            beforeCount++;
            if (date && verifyDateFilter(res, attr)) {
              equalCount++;
            } else if (valueRes.includes(slugify(attr.value))) {
              equalCount++;
            }
          }
        }
        return (beforeCount === equalCount)
      }
      return false;
    }
  }
  return true;
}
/** * FU - 009.3 - Retorna a propriedade onde o texto for igual a value *
 *
 * @param { any } resText - Objeto a ser feito a busca.
 * @param { string } valueText - valor a ser buscado.
 * @returns { string } retorna uma string
 *
 * @throws {  } null.
 *
 * @example
 * const search = textList({ name: 'teste', idade: 15 }, 'te');
 * irá retornar 'name'
 **/
export function textList(resText: any, valueText: string) {
  if (valueText !== '') {
    const objKeysText = Object.keys(resText);
    for (const keyText of objKeysText) {
      const valueRes = resText[keyText] ? slugify(resText[keyText]) : '';
      if (
        keyText !== 'created_at' &&
        keyText !== 'updated_at' &&
        valueRes.includes(slugify(valueText))
      ) {
        return keyText;
      }
    }
    return '';
  } else {
    return '';
  }
}

/** * FU - 010 - Gera uma string aleatória com a quantidade de caracteres especificada *
 *
 * @param { number } size - Quantidade de caracteres especificada.
 * @returns { string } retorna uma string com caracteres aleatórios
 * @throws {  } null.
 *
 * @example
 * const randomString = generateStringRandom(4);
 * irá retornar 'Ab19'
 **/
export function generateStringRandom(size: number): string {
  let randomString = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < size; i++) {
    randomString += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return randomString;
}

/** * FU - 011 - Cria um modal de loading *
 *
 * @param { MatDialog } dialog - variavel de criação do dialog
 * @param { string } title - titulo do modal de loading.
 * @param { string } text - texto do modal de loading.
 * @param { boolean } disableClose - Pofer fechar o modal ao clicar forar dele.
 * @returns { MatDialogRef<ModalLoadingComponent> } retorna um dialog de loading
 * @throws {  } null.
 *
 **/
export function openModalLoading(
  dialog: MatDialog,
  title = 'Carregando',
  text = 'Por favor espere alguns segundos',
  disableClose = true
): MatDialogRef<ModalLoadingComponent> {
  return dialog.open(ModalLoadingComponent, {
    width: '25rem',
    disableClose,
    data: {
      title,
      text,
    },
  });
}

/** * FU - 012 - Fazer a inserção, atualização e deletar query params *
 *
 * @param { string } param - nome do query param.
 * @param { string } value - valor do query param.
 * @param { boolean } remove - verificação se vai deletar ou não.
 * @param { RequestAttribute[] } attributes - Array de query params.
 * @returns { RequestAttribute[] } retorna o array de atributos
 * @throws {  } null.
 *
 * @example
 * case 01 - atualizando um query params
 * changeRequestParameter('type', '1512', false, [{param: 'type', value: '123'}]);
 * attribute fica igual a [{param: 'type', value: '1512'}]
 *
 * case 02 - deletando um query params
 * changeRequestParameter('type', '1512', true, [{param: 'type', value: '123'}]);
 * attribute fica igual a []
 *
 * case 03 - inserindo um query params
 * changeRequestParameter('test', '147', false, [{param: 'type', value: '123'}]);
 * attribute fica igual a [{param: 'type', value: '123'}, {param: 'test', value: '147'}]
 **/
export function changeRequestParameter(param: string, value: string, remove: boolean, attributes: RequestAttribute[] = []): RequestAttribute[] {
  const indexParam = attributes.findIndex(res => res.param === param);
  if (indexParam !== -1) {
    if (remove) {
      attributes.splice(indexParam, 1)
    } else {
      attributes[indexParam].value = value;
    }
  } else if (!remove) {
    attributes.push({ param: param, value: value });
  }
  return attributes;
}

/** * FU - 013 - Faz uma verificação no array para verificar se tem a propriedade 'showMobile' *
 *
 * @param { any } content - listagem de dados para verificar se tem a propriedade 'showMobile'.
 * @returns { boolean } retorna um boolean sendo false para quando a propriedade 'showMobile' em alguns dos objs a propriedade é igual a true
 * @throws {  } null.
 *
 * @example
 * case 01 - quando a propriedade showMobile existe e tem alguma igual a true
 * const verifyShowMobile = verifyShowMobile([{ showMobile: true }, { showMobile: false }]);
 * irá retornar false
 *
 * case 02 - quando a propriedade showMobile é igual a false ou não existe
 * const verifyShowMobile = verifyShowMobile([{ showMobile: false }, { showMobile: false }]);
 * irá retornar true
 **/
export function verifyShowMobile(content: any): boolean {
  return content.filter(res => res.showMobile === true).length === 0;
}

/** * FU - 014 - Faz uma verificação para mostrar os dados na tela *
 *
 * @param { DataCardDocument } info - obj de verificação do tipo.
 * @param { dataValue } dataValue - obj de dados.
 * @returns { string } retorna uma string se existir ele verifica e mostra o dado perante ao tipo senão ele mostra um valor padrão '-'
 * @throws {  } null.
 *
 * @example
 * case 01 - quando existe a propriedade
 * const showTheData = showTheData({ "title": "Instalação", "text": "installationCode", "type": "text", "showMobile": true }, { "installationCode": "UG-04" });
 * irá retornar "UG-04"
 *
 * case 02 - quando não existe a propriedade
 * const showTheData = showTheData({ "title": "Instalação", "text": "installationCode", "type": "text", "showMobile": true }, {});
 * irá retornar "-"
 **/
export function showTheData(info: DataCardDocument, dataValue: any): string {
  let value = dataValue;
  info.text.split('||').forEach((item, index, arr) => {
    if (value && value[item] !== null && value[item] !== undefined && value[item] !== '') {
      value = value[item];
    } else {
      value = (info.type === 'affirmation' ? false : '-');
      arr.length = index + 1;
    }
  });
  return (value !== '-' ? `${convertData(info.type, value)}` : '-');
}

/** * FU - 015 - Faz a conversão dos dados dependendo do tipo *
 *
 * @param { string } type - tipo do dado.
 * @param { any } value - valor a ser convertido.
 * @returns { any } dado retornado após a conversão
 * @throws {  } null.
 *
 * @example
 * case 01 - quando existe um tipo de consversão
 * const convertData = convertData("affirmation", true);
 * irá retornar "Sim"
 *
 * case 02 - quando não existe um tipo de consversão
 * const convertData = convertData("test", "-");
 * irá retornar "-"
 **/
export function convertData(type: string, value: any): any {
  let newValue = '';
  switch (type.toLowerCase()) {
    case 'datetime':
      newValue = moment.parseZone(value).isValid() ? `${moment.parseZone(value).format('DD/MM/YYYY')} às ${moment.parseZone(value).format('HH:mm')}` : value;
      break;
    case 'date':
      newValue = moment.parseZone(value).isValid() ? `${moment.parseZone(value).format('DD/MM/YYYY')}` : value;
      break;
    case 'time':
      newValue = moment.parseZone(value).isValid() ? `${moment.parseZone(value).format('HH:mm')}` : value;
      break;
    case 'affirmation':
      newValue = (value ? 'Sim' : 'Não');
      break;
    case 'type':
      newValue = (mockTypeFilter[value] ? mockTypeFilter[value] : value);
      break;
    case 'state':
      newValue = (mockOptionsState[value] ? mockOptionsState[value] : value);
      break;
    default:
      newValue = value;
  }
  return newValue;
}

/** * FU - 016 - Faz ao download de um link especifico *
 *
 * @param { string } href - a url do item de download.
 * @param { string } name - nome a ser colocado no arquivo do downlod.
 * @returns { void }
 * @throws {  } null.
 *
 * @example
 * downloadLink("arquivo.txt", 'meu-arquivo.txt');
 **/
export function downloadLink(href: string, name: string): void {
  const downloadLink = document.createElement("a");
  downloadLink.href = href;
  downloadLink.download = name;
  downloadLink.click()
};

/** * FU - 017 - Volta o scroll da tela ao topo *
 *
 * @returns { void }
 * @throws {  } null.
 *
 * @example
 * Volta o scroll da tela ao topo
 **/
export function scrollToTop(): void {
  const contentElement = document.querySelector('.content');
  if (contentElement) {
    if (document.hasFocus()) {
      (document.activeElement as HTMLElement).blur();
    }
    contentElement.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
}

/** * FU - 018 - Volta para a tela de busca se uma determinada rota não for aparecer no offline *
 *
 * @param { boolean } status - status da conexão.
 * @param { Router } router - componente de rota para poder ir para a rota search.
 * @returns { void }
 * @throws {  } null.
 *
 * @example
 * se o status for false ele retorna para a tela de busca
 **/
export function returnPageSearch(status: boolean, router: Router): void {
  if (!status) {
    router.navigate(['search']);
  }
}

export function formatStateRelatedActive(active) {
  return mockStatus[active.tag] && mockStatus[active.tag][active.state] ? mockStatus[active.tag][active.state].text : active.state;
}

export function formatLinkRelatedActive(active, stateActive): LinkInterface {
  return { label: stateActive[active.property], href: `#/search/see-document/${stateActive.type}/${stateActive._id}`, target: '_self', font: 'small' };
}

export function mountObjRelatedActive(arrayActive: any[], mockActive): any[] {
  const keyActive = Object.keys(arrayActive), objActive = { ...mockActive };
  const related = [];
  keyActive.forEach(type => {
    const nowActive = [], mockAct = objActive[type];
    if (mockAct) {
      arrayActive[type].forEach(active => {
        if (active.actives.length > 0) {
          nowActive.push({ ...active, tag: mockAct.tag, property: mockAct.property });
        }
      });
      if (nowActive.length > 0) {
        related.push({
          title: mockAct.title,
          states: nowActive,
          description: mockAct.description
        })
      }
    }
  });
  return related;
}

const plotlyService = new PlotlyService();

export function downloadGraphic(id: string, name: string, dialog: MatDialog, legendId?: string) {
  const loading = openModalLoading(dialog);
  const plotly = plotlyService.getPlotly();
  const graph = document.querySelector(`#${id} svg`);
  if (graph) {
    const optionsPlotly: any = { format: 'png' }, widthGraph = parseInt(graph.getAttribute('width'));
    if (widthGraph > 8000) {
      optionsPlotly.width = 8000;
    }
    plotly.toImage(id, optionsPlotly).then(async(url) => {
      let urlImage = url;
      const legend = document.querySelector(`#${legendId}`);
      if (legendId && legend) {
        await domtoimage.toPng(legend).then(async (dataUrl: string) => {
          urlImage = await mergeImages([url.replace('data:image/png;base64,', ''), dataUrl.replace('data:image/png;base64,', '')], {
            direction: true,
            color: '#fff',
            align: 'end'
          });
        });
      }
      loading.close();
      downloadLink(urlImage, name);
    });
  } else {
    loading.close();
  }
}

export async function getImage(data) {
  try {
    return await html2canvas(data)
      .then((canvas) => {
        const img = canvas.toDataURL('image/png');
        canvas.remove();
        return Promise.resolve(img);
      })
      .catch((error) => {
        return Promise.resolve(null);
      });
  } catch (error) {
    return Promise.resolve(null);
  }
}

export function mountLegendGraphic(name: string, color: string, icon: string = 'square'): LegendGraphic {
  return {
    dataSetName: name,
    label: name,
    backgroundColor: color,
    borderColor: color,
    order: 1,
    icon: icon
  }
}

export function removeItemSessionStorage(item: string): void {
  sessionStorage.removeItem(item);
}

export function setItemSessionStorage(item: string, value: string): void {
  sessionStorage.setItem(item, value);
}

export function getItemSessionStorage(item: string): string {
  return sessionStorage.getItem(item);
}

export function setItemLocalStorage(item: string, value: string): void {
  localStorage.setItem(item, value);
}

export function getItemLocalStorage(item: string): string {
  return localStorage.getItem(item);
}

export function removeItemLocalStorage(item: string) {
  localStorage.removeItem(item);
}

const authService = new AuthService();
export function getConfigApi(): ConfigApiDynamicTable {
  return {
    url: environment.API_URL,
    token: authService.getAuthorizationToken()
  }
}

export function limitDataGraphics(dataArray, limitData) {
  let arrayValue = dataArray
  const keysLimit = limitData.filter(res => res.hidden);
  if (keysLimit.length > 0) {
    arrayValue = dataArray.filter(res => {
      const limitItem = keysLimit.findIndex(item => item.key === res.name);
      return (limitItem === -1 ? true : false)
    });
  }
  return arrayValue;
}

export function formatorNumber(number) {
  return number.toLocaleString('pt-BR');
}

export function limitDataGraphicsPie(dataArray, limitData) {
  let arrayValue = JSON.parse(JSON.stringify(dataArray));
  const keysLimit = limitData.filter(res => res.hidden);
  if (keysLimit.length > 0) {
    const values = {
      labels: [],
      values: [],
      colors: [],
      customdata: [],
      text: [],
    }
    dataArray[0].labels.forEach((res, index) => {
      const limitItem = keysLimit.findIndex(item => item.key === res);
      if (limitItem === -1) {
        values.labels.push(res);
        values.values.push(dataArray[0].values[index]);
        values.colors.push(dataArray[0].marker.colors[index]);
        values.customdata.push(formatorNumber(dataArray[0].values[index]));
        values.text.push(dataArray[0].marker.colors[index]);
      }
    });
    arrayValue[0].labels = values.labels;
    arrayValue[0].values = values.values;
    arrayValue[0].marker.colors = values.colors;
    arrayValue[0].customdata = values.customdata;
    arrayValue[0].text = values.text;
  }
  return arrayValue;
}

export function sortDataGraphBar(count: number, allCount: number, dataValue: any[]): any[] {

  let arrayValue = [];
  if (count !== allCount) {
    arrayValue = dataValue.sort((a, b) => sortAsc(a, b, 'type'));
  }
  return arrayValue
}

export function verifyLineDash(line): string {
  return (line === 'dash' || line === 'dot' ? line : '');
}

export function returnLineGraphic(line): string {
  if (line) {
    return (line?.dash === 'dash' || line?.dash === 'dot' ? 'trace-line' : 'line');
  } else {
    return 'square';
  }
}

function isValidVideoUrl(url: string): boolean {
  const urlPattern = /^(https?:\/\/)?((www\.)?youtube\.com|youtu\.be|vimeo\.com)\/.+$/;
  return urlPattern.test(url);
}

export function exportUrlVideo(_sanitizer: DomSanitizer, urlVideo: string): any {
  if (urlVideo && isValidVideoUrl(urlVideo)) {
    return _sanitizer.bypassSecurityTrustResourceUrl(urlVideo);
  } else {
    return '';
  }
}
